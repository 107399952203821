
/**
 * 根据id数组获取各父级id
 * @param {*多个子id} childArr 
 * @param {**已经扁平化的数组} flatList 
 * @param {*父id名} PId 
 * @param {*id名} Id 
 * @returns 
 */
const getParentIdList = function (childArr, flatList, PId, Id, labelName) {
  let tree = []
  childArr.forEach(item => {
    tree.push(getParentIds(item, flatList, PId, Id, labelName))
  })
  return tree
}

/**
 * 根据id获取各父级id
 * @param {子元素id值} childId 
 * @param {*已经扁平化的数组} flatList 
 * @param {*父id名} PId 
 * @param {*id名} Id 
 * @returns 
 */
const getParentIds = function (childId, flatList, PId, Id, labelName) {
  if (!labelName) {
    labelName = Id
  }
  let arr = []
  flatList.forEach(item => {
    if (item[Id] == childId) {
      arr.unshift(item[labelName])
      if (item[PId]) {
        arr.unshift(...getParentIds(item[PId], flatList, PId, Id, labelName))
      }
    }
  })
  return arr
}

/**
 * 将数组扁平化
 * @param {*数组} list 
 * @param {*子数组名} childName 
 * @returns 
 */
const getFlatlist = function (list, childName) {
  let arr = []
  list.forEach(item => {
    let sitem = { ...item }
    delete sitem[childName]
    if (item[childName] && item[childName].length > 0) {
      arr = arr.concat(sitem, ...getFlatlist(item[childName], childName))
    } else {
      delete item[childName]
      arr.push(item)
    }
  })
  return arr
}


/**
 * 将对象数组根据某一属性分组
 * @param {*数组} sortData 
 * @param {*分组规则函数，可返回要分组的属性值} keyFunc 
 * @returns 
 */
const sortClass = (sortData, keyFunc) => {
  const groupBy = (array, keyFunc) => {
    let groups = new Map();
    array.forEach((item) => {
      let key = keyFunc(item);
      if (key != null && key !== undefined) {
        if (!groups.has(key)) {
          groups.set(key, []);
        }
        groups.get(key).push(item);
      }
    });
    return Array.from(groups.values());
  };
  return groupBy(sortData, keyFunc);
};



module.exports = {
  getFlatlist,
  getParentIdList,
  getParentIds,
  sortClass
}